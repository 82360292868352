'use client'

import type { BoxPropValue } from '@fortum/elemental-ui'
import { Box } from '@fortum/elemental-ui'

import { ContentfulImage } from '@/shared/components/ContentfulImage'
import { StyledLink } from '@/shared/components/StyledLink'
import type { ImageLinkEntry } from '@/shared/contentful/types'
import { getLinkEntryUrl } from '@/shared/contentful/utils'

export type ImageLinkProps = Omit<ImageLinkEntry, '__typename' | 'sys'> & {
  /**
   * Image height
   */
  height?: BoxPropValue
  /**
   * Image width
   */
  width?: BoxPropValue
  /**
   * Image source URL
   */
  src?: string
  /**
   * Image alt text
   */
  alt?: string
  /**
   * Margin styles
   */
  margin?: BoxPropValue
}

/**
 * Helper component that wraps Image under Action link component
 */
export const ImageLink = ({
  label,
  url,
  image,
  width,
  page,
  height,
  src,
  alt,
  margin,
}: ImageLinkProps) => (
  <StyledLink
    href={getLinkEntryUrl({ page, url })}
    target="_blank"
    title={label || ''}
    margin={margin}
  >
    <Box width={width} height={height} position="relative">
      <ContentfulImage imageEntry={image} src={src} alt={alt} />
    </Box>
  </StyledLink>
)
