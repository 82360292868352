import { useTranslations } from 'next-intl'

import { ContentText } from '@fortum/elemental-ui'

import { useTheme } from '@/shared/hooks/useTheme'

import { NotificationBanner } from '../NotificationBanner'

export const CoopNotification = () => {
  const t = useTranslations('coopNotification')
  const { colors } = useTheme()

  return (
    <NotificationBanner
      type="alert"
      heading={t('title')}
      shouldHideCloseButton={true}
      numberOfLines={12}
    >
      <ContentText paragraph size="s">
        {t('information')}
      </ContentText>
      <ContentText paragraph size="s" color={colors.textSecondary}>
        {t('disclaimer')}
      </ContentText>
    </NotificationBanner>
  )
}
