import type { ChangeEvent } from 'react'
import { useController } from 'react-hook-form'

import { Box, ContentText, IconPinFilled, Loader, spacing } from '@fortum/elemental-ui'

import { StyledInput } from '@/open-web/components/Checkout/styled'
import type { FormFiledValues, QuickSearchForm } from '@/open-web/components/QuickSearch/types'
import { Icon } from '@/shared/components/Icon'
import { useTheme } from '@/shared/hooks/useTheme'

type InputProps = FormFiledValues & {
  texts: {
    topText?: string
    label: string
    errorMessage: string
  }

  isError?: boolean
  isLoading?: boolean
  onChange: (name: keyof QuickSearchForm, value: string) => void
}

export const PostalCodeInput = ({
  texts,
  isError,
  isLoading,
  onChange,
  ...formProps
}: InputProps) => {
  const { topText, ...inputTexts } = texts
  const { colors } = useTheme()

  const {
    field: { ref, ...fieldProps },
    fieldState,
  } = useController({ ...formProps })

  let maxLength = 6

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value

    onChange(fieldProps.name, newValue)
    maxLength = newValue.includes(' ') ? 6 : 5
    event.target.maxLength = maxLength
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={spacing.xxxs}
      flex={{ default: 'none', s: '1', l: 'none' }}
    >
      {topText && <ContentText color={colors.textSecondary}>{topText}</ContentText>}
      <StyledInput
        {...fieldProps}
        {...inputTexts}
        inputRef={ref}
        onChange={handleChange}
        error={Boolean(fieldState.error) || isError}
        data-testid={`spot-price-form-${formProps.name}`}
        rightIcon={isLoading ? <Loader /> : <Icon icon={IconPinFilled} />}
        inputProps={{
          maxLength: maxLength,
          inputMode: 'numeric',
          pattern: String.raw`^\d{0,5}( \d{0,2})?$`,
        }}
      />
    </Box>
  )
}
