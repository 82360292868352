'use client'

import { useTranslations } from 'next-intl'
import type { DOMAttributes } from 'react'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTheme } from 'styled-components'

import {
  Box,
  ButtonPill,
  ContentText,
  Section,
  fontSizes,
  px2rem,
  spacing,
} from '@fortum/elemental-ui'

import { AdditionalInfoForm } from '@/open-web/components/QuickSearch/AdditionalInfoForm'
import type { QuickSearchForm } from '@/open-web/components/QuickSearch/types'
import { QUICK_SEARCH_CONFIG } from '@/open-web/components/QuickSearch/utils'
import { ForwardLink } from '@/shared/components/ForwardLink'
import { SpotPriceMapSweden } from '@/shared/components/SpotPriceMap/Sweden'
import type { SpotEntry } from '@/shared/components/SpotPrices/commons'
import { SpotPricesTrpc } from '@/shared/components/SpotPrices/SpotPrices'
import { SpotPricesStoreProvider } from '@/shared/components/SpotPrices/StoreProvider'
import { StyledHeading } from '@/shared/components/StyledHeading'
import type { SpotPriceSectionEntry } from '@/shared/contentful/types'
import { getLinkEntryUrl } from '@/shared/contentful/utils'
import { browserEnvs } from '@/shared/envs'
import type { PriceAreaCode } from '@/shared/graphql/schema/commonBackend/graphql'
import { useLocale } from '@/shared/locale'
import { useGlobalStore } from '@/shared/store/provider'

import { PostalCodeInput } from './parts/PostalCodeInput'

export type SpotPriceData = {
  priceArea: PriceAreaCode
  unit: string
  spotPriceSeries: SpotPriceSeries[]
}

export type SpotPriceSeries = {
  atUTC: string
  spotPrice?:
    | {
        value: number
        total: number
        vatPercentage?: number | null | undefined
      }
    | null
    | undefined
}

export type SpotPriceSectionProps = SpotPriceSectionEntry & {
  /**
   * Today's average spot prices for each price area.
   */
  dailySpotPrices: SpotPriceData[]
}

export const SpotPriceSection = (props: SpotPriceSectionProps) => {
  const { colors } = useTheme()
  const t = useTranslations('spotPriceSection')
  const locale = useLocale()
  const [streetAddress, setStreetAddress] = useState<string>()
  const [place, setPlace] = useState<string>()
  const [showSpotPriceGraph, setShowSpotPriceGraph] = useState(false)
  const [mapPriceArea, setMapPriceArea] = useState<PriceAreaCode>()
  const [isPriceAreaError, setIsPriceAreaError] = useState(false)
  const [hasClickedMap, setHasClickedMap] = useState(false)

  const href = props.forwardLink && getLinkEntryUrl(props.forwardLink)

  const { postalCode, _isLoading, isMultipleAreas, deliveryArea, requestDeliveryArea } =
    useGlobalStore((state) => state.housingInfo)

  useEffect(() => {
    if (deliveryArea && !isMultipleAreas) {
      setMapPriceArea(deliveryArea)
      setShowSpotPriceGraph(true)
    } else {
      setShowSpotPriceGraph(false)
    }
  }, [deliveryArea, isMultipleAreas])

  useEffect(() => {
    setMapPriceArea(deliveryArea)
  }, [deliveryArea])

  useEffect(() => {
    if (postalCode) {
      setIsPriceAreaError(false)
    }
  }, [postalCode])

  const form = useForm<QuickSearchForm>({
    mode: 'onTouched',
    values: {
      postalCode: postalCode || '',
      city: place || '',
      streetAddress: streetAddress || '',
      housingSize: '',
      estimatedConsumption: '',
    },
  })

  const isPostalCodeError = isPriceAreaError || Boolean(form.formState.errors.postalCode)

  const onChange = (name: keyof QuickSearchForm, value: string) => {
    switch (name) {
      case 'postalCode':
        form.setValue('postalCode', value)
        setIsPriceAreaError(false)
        break
      case 'city':
        setPlace(value)
        break
      case 'streetAddress':
        setStreetAddress(value)
        break
    }
  }

  const handleSubmit: DOMAttributes<HTMLFormElement>['onSubmit'] = (event) => {
    event.preventDefault()

    const postalCodeValue = form.getValues('postalCode')
    const formattedPostalCode = postalCodeValue.replaceAll(/\s/g, '')

    if (postalCode === formattedPostalCode && !isPostalCodeError && !hasClickedMap) {
      setShowSpotPriceGraph(true)
      return
    }

    setHasClickedMap(false)

    requestDeliveryArea(formattedPostalCode, streetAddress, place)
      .then()
      .catch(() => {
        setIsPriceAreaError(true)
      })
  }

  const mapSpotPriceDataToSpotEntry = (spotPriceData: SpotPriceData): SpotEntry => ({
    time: spotPriceData.spotPriceSeries[0].atUTC,
    unit: spotPriceData.unit,
  })

  const mapDailySpotPrices = () => {
    const priceAreaLabels: { [key: string]: string } = {}

    props.dailySpotPrices.forEach((spotPriceData) => {
      let totalValue = 0
      let totalCount = 0

      spotPriceData.spotPriceSeries.forEach((series) => {
        if (series.spotPrice?.value !== undefined) {
          totalValue += series.spotPrice.value
          totalCount++
        }
      })

      if (totalCount > 0) {
        const averagePrice = totalValue / totalCount
        const formattedAveragePrice = new Intl.NumberFormat(locale, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(averagePrice)

        const totalWithUnit = `${formattedAveragePrice} ${spotPriceData.unit}`
        priceAreaLabels[spotPriceData.priceArea] = totalWithUnit
      }
    })

    return priceAreaLabels
  }

  const handleMapClick = (priceArea: PriceAreaCode) => {
    setMapPriceArea(priceArea)
    setHasClickedMap(true)
    setShowSpotPriceGraph(true)
  }

  const toggleSpotPriceGraph = () => {
    setShowSpotPriceGraph(!showSpotPriceGraph)
  }

  //TODO: Add the other maps to the switch when they are created
  const renderSpotPriceMap = () => {
    const country = browserEnvs.NEXT_PUBLIC_COUNTRY
    switch (country) {
      case 'SE':
        return (
          <SpotPriceMapSweden onClick={handleMapClick} priceAreaLabels={mapDailySpotPrices()} />
        )
      // case 'NO':
      //   return <SpotPriceMapNorway priceAreaLabels={mapDailySpotPrices()} />;
      // case 'FI':
      //   return <SpotPriceMapFinland priceAreaLabels={mapDailySpotPrices()} />;
      default:
        return null
    }
  }

  const getDefaultPriceArea = (): PriceAreaCode => {
    const country = browserEnvs.NEXT_PUBLIC_COUNTRY
    switch (country) {
      case 'SE':
        return 'SE1'
      case 'NO':
        return 'NO1'
      case 'FI':
        return 'FI'
      default:
        return 'SE1'
    }
  }

  return (
    <Section pv={{ default: spacing.xs, l: spacing.m }}>
      {showSpotPriceGraph ? (
        <>
          <SpotPricesStoreProvider
            initialValues={{
              mappedData: props.dailySpotPrices.map(mapSpotPriceDataToSpotEntry),
            }}
            initialKPIs={undefined}
          >
            <SpotPricesTrpc
              onToggleMapDisplay={toggleSpotPriceGraph}
              priceArea={mapPriceArea ?? getDefaultPriceArea()}
              showMapButton={true}
              showTopTab={true}
            />
          </SpotPricesStoreProvider>
          {props.forwardLink?.label && href && (
            <Box
              display="flex"
              padding={spacing.xs}
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              gap={spacing.xs}
            >
              <ForwardLink href={href}>{props.forwardLink?.label}</ForwardLink>
            </Box>
          )}
        </>
      ) : (
        <Box
          display="flex"
          flexDirection={{ default: 'column', l: 'row' }}
          gap={{ default: spacing.none, xxl: px2rem(100) }}
          height="100%"
        >
          <Box
            display="flex"
            flex={{ default: 1, xxl: 2 }}
            flexDirection="column"
            gap={spacing.xxs}
            pt={{ default: spacing.none, l: spacing.xs, xxl: spacing.none }}
          >
            {props.topic && (
              <ContentText
                textTransform="uppercase"
                textColor={colors.textPositive}
                fontSize={fontSizes.l}
              >
                {props.topic}
              </ContentText>
            )}
            {props.title && (
              <StyledHeading level={2} styledAs={3} textColor={colors.textLarge}>
                {props.title}
              </StyledHeading>
            )}
            {props.simpleContent && <ContentText>{props.simpleContent}</ContentText>}

            <FormProvider {...form}>
              <form onSubmit={handleSubmit}>
                <Box
                  display="flex"
                  flexDirection={{ default: 'column', s: 'row', l: 'column', xxl: 'row' }}
                  mt={{ default: spacing.xs, m: spacing.m }}
                  gap={{ default: spacing.xs, l: spacing.xxs }}
                  flexWrap="wrap"
                  alignItems={{ default: 'stretch', s: 'flex-start' }}
                >
                  <PostalCodeInput
                    {...QUICK_SEARCH_CONFIG.postalCode}
                    data-testid="spot-price-postal-code-input"
                    texts={{
                      label: t('postalCode'),
                      errorMessage: t('errorInvalidPostalCode'),
                    }}
                    onChange={onChange}
                    isError={isPriceAreaError}
                    isLoading={_isLoading}
                  />

                  <ButtonPill
                    flex={{ default: 'none', s: '1', l: 'none' }}
                    size="l"
                    type="submit"
                    disabled={_isLoading || isPostalCodeError}
                  >
                    {t('seeTodaysPrice')}
                  </ButtonPill>

                  {isMultipleAreas && (
                    <Box pt={{ default: spacing.xs, l: spacing.m }}>
                      <AdditionalInfoForm onChange={onChange} />
                    </Box>
                  )}
                </Box>
              </form>
            </FormProvider>
          </Box>
          <Box
            display="flex"
            flex={{ default: 1.4, xxl: 1.5 }}
            justifyContent="space-evenly"
            alignItems="center"
            flexDirection="column"
            justifySelf="space-between"
            pt={{ default: spacing.xs, m: spacing.m, l: spacing.none }}
          >
            {renderSpotPriceMap()}
          </Box>
        </Box>
      )}
    </Section>
  )
}
