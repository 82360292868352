'use client'

import Image from 'next/image'
import { useTranslations } from 'next-intl'

import { Box, Col, ContentText, Hidden, Row, spacing } from '@fortum/elemental-ui'

import type { AppSectionEntry } from '@/shared/contentful/types'
import { useTheme } from '@/shared/hooks/useTheme'

import { ContentfulImage } from '../ContentfulImage'
import { ForwardLink } from '../ForwardLink'
import { HighlightedHeading } from '../HighlightedHeading'
import { MobileAppDownload } from '../MobileAppDownload'

export type AppProps = Omit<AppSectionEntry, 'backgroundColor'> & {
  /**
   * QR code data URL
   */
  qrCodeDataUrl?: string
}

/**
 * App component
 */
export const App = ({
  title,
  qrCodeDataUrl,
  simpleContent,
  image,
  forwardLink,
  appLinks,
}: AppProps) => {
  const theme = useTheme()
  const t = useTranslations('app')

  const renderAppDownload = () => (
    <Row gap={{ default: spacing.xxs, m: spacing.xs, l: spacing.m }}>
      <Col gap={spacing.xxs} display="flex" justifyContent={{ default: 'center', l: 'flex-start' }}>
        <Hidden below="s">
          {qrCodeDataUrl && (
            <Box
              height="8rem"
              width="8rem"
              borderRadius={spacing.xxxxs}
              background={theme.colors.backgroundPrimary}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Image height={120} width={120} src={qrCodeDataUrl} alt={t('qrcode')} />
            </Box>
          )}
        </Hidden>
        {appLinks && (
          <Box>
            <MobileAppDownload {...appLinks} />
          </Box>
        )}
      </Col>
      <Col display="flex" justifyContent={{ default: 'center', l: 'flex-start' }}>
        <ForwardLink color={theme.colors.textLightOnDark} href={forwardLink?.url}>
          {forwardLink?.label}
        </ForwardLink>
      </Col>
    </Row>
  )

  return (
    <Box display="flex" flexDirection="column">
      <Row
        gap={{ default: spacing.xxs, m: spacing.xs }}
        display="flex"
        justifyContent="center"
        flexDirection={{ l: 'row-reverse' }}
      >
        <Col
          l={5}
          display="flex"
          flexDirection="column"
          textAlign={{ default: 'center', l: 'left' }}
          gap={{ default: spacing.xxs, m: spacing.xs, l: spacing.m }}
        >
          <Box gap={spacing.xxxs} display="flex" flexDirection="column">
            {title && <HighlightedHeading text={title} />}
            <ContentText color={theme.colors.textLightOnDark}>{simpleContent}</ContentText>
          </Box>
          <Hidden below="l">{renderAppDownload()}</Hidden>
        </Col>
        <Col
          l={5}
          display="flex"
          justifyContent="center"
          alignItems="center"
          height={{ default: '418px', l: 'auto' }}
        >
          {image && (
            <Box>
              <ContentfulImage
                wrapperProps={{
                  position: 'relative',
                  height: { default: '360px', m: '403px' },
                  width: { default: '275px', m: '318px' },
                }}
                imageEntry={image}
                style={{ objectFit: 'contain' }}
                maxWidth="500"
              />
            </Box>
          )}
        </Col>
        <Hidden above="l">{renderAppDownload()}</Hidden>
      </Row>
    </Box>
  )
}
