import type { Article, WithContext } from 'schema-dts'

import { stringifyParams } from './url'
import type { ArticleEntry, ImageEntry } from '../contentful/types'
import { getAssetUrl } from '../contentful/utils'

/**
 * Get article structured data in JSON-LD format.
 * See https://developers.google.com/search/docs/appearance/structured-data/article
 */
export const getArticleJsonLd = (article: ArticleEntry): WithContext<Article> => {
  return {
    '@context': 'https://schema.org',
    '@type': 'Article',
    headline: article.title || '',
    author: {
      '@type': 'Person',
      name: article.author?.fullName || '',
    },
    image: article.heroImage ? getImages(article.heroImage) : undefined,
    dateModified: article.updated || '',
  }
}

const getImages = (image: ImageEntry | undefined) => {
  return [16 / 9, 4 / 3, 1].map((aspectRatio) => getImage(image, aspectRatio)).filter(Boolean)
}

const getImage = (image: ImageEntry | undefined, aspectRatio: number) => {
  const imageUrl = getAssetUrl(image?.image)

  if (!imageUrl) {
    return
  }

  const width = 1200
  const height = Math.round(width / aspectRatio)

  const params = stringifyParams({ w: width, h: height, fit: 'fill', f: 'faces' })

  return `${imageUrl}?${params}`
}

/**
 * Get organization structured data in JSON-LD format.
 * See https://developers.google.com/search/docs/data-types/organization
 */

export const getOrganizationJsonLd = () => {
  return {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    url: 'https://www.fortum.com',
    logo: 'https://www.fortum.com/themes/custom/fortum_base/logo.png',
  }
}

/**
 * Get breadcrumb list structured data in JSON-LD format.
 * See https://developers.google.com/search/docs/data-types/breadcrumb
 */

export const getBreadcrumbListJsonLd = (
  itemListElement: { id: string | undefined; name: string }[],
) => {
  return {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: itemListElement.map((item, index) => ({
      '@type': 'ListItem',
      name: item.name,
      position: index + 1,
      item: item.id,
    })),
  }
}

/**
 * Get FAQ page structured data in JSON-LD format.
 * See https://developers.google.com/search/docs/data-types/faqpage
 */

export const getFaqPageJsonLd = (
  title: string,
  mainEntity: {
    '@type': string
    name: string
    acceptedAnswer: { '@type': string; text: string }
  }[],
) => {
  return {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    name: title,
    mainEntity,
  }
}
