'use client'

import type { MouseEventHandler } from 'react'

import { ContentText, px2rem, spacing } from '@fortum/elemental-ui'

import type { IconId } from '@/shared/components/Icon'
import { Icon } from '@/shared/components/Icon'
import { StyledLink } from '@/shared/components/StyledLink'
import { useTheme } from '@/shared/hooks/useTheme'
import { getFocusColor, getHoverColor } from '@/shared/utils/colors'

type KeyLinkProps = {
  label?: string | null
  href?: string
  icon?: string | null
  dataTestId?: string
  onClickHandler?: () => void
  isFixedHeight?: boolean
}

export const KeyLink = ({
  label,
  href,
  icon,
  dataTestId,
  onClickHandler,
  isFixedHeight = true,
}: KeyLinkProps) => {
  const theme = useTheme()
  const { backgroundPrimary, brandPrimary } = theme.colors
  const justifyContent = (() => {
    if (isFixedHeight) {
      if (icon) {
        return 'space-between'
      }
      return 'space-around'
    }
    return 'flex-start'
  })()
  return (
    <StyledLink
      display="flex"
      border={`${px2rem(2)} solid ${brandPrimary}`}
      borderRadius={spacing.xxxs}
      flexDirection="column"
      noUnderline
      background={backgroundPrimary}
      noUnderlineHover
      href={href}
      scroll={false}
      alignItems="center"
      gap={spacing.xxs}
      p={spacing.xxs}
      width="100%"
      height={isFixedHeight ? px2rem(127) : '100%'}
      justifyContent={justifyContent}
      hover={{
        background: getHoverColor(theme, backgroundPrimary),
        border: `${px2rem(2)} solid ${getHoverColor(theme, brandPrimary)}`,
      }}
      focus={{
        background: getFocusColor(theme, backgroundPrimary),
        border: `${px2rem(2)} solid ${getFocusColor(theme, brandPrimary)}`,
      }}
      active={{
        background: getHoverColor(theme, backgroundPrimary),
        border: `${px2rem(2)} solid ${getHoverColor(theme, brandPrimary)}`,
      }}
      data-testid={dataTestId}
      onClick={onClickHandler as MouseEventHandler<HTMLAnchorElement>}
    >
      <Icon icon={icon as IconId} size={48} />
      <ContentText color="currentColor" textAlign="center">
        {label}
      </ContentText>
    </StyledLink>
  )
}
